@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

:root {
  --desktop-topbar-height: 56px;
  --max-width: 1100px;
  --border-radius: 12px;

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;

  --primary-color: #7413BD;
  --secondary-color: #5B5B5B;
  --sider-bg-color: #F8EEFF;

  --font-size-11: clamp(11px, 1vw, 11px);
  --font-size-12: clamp(12px, 1vw, 12px);
  --font-size-13: clamp(13px, 1vw, 13px);
  --font-size-15: clamp(14px, 1vw, 15px);
  --font-size-14: clamp(14px, 1vw, 14px);
  --font-size-16: clamp(14px, 1vw, 16px);
  --font-size-17: clamp(14px, 1vw, 17px);
  --font-size-18: clamp(14px, 1vw, 18px);
  --font-size-19: clamp(16px, 1vw, 19px);
  --font-size-20: clamp(16px, 1vw, 20px);
  --font-size-21: clamp(17px, 1vw, 21px);
  --font-size-22: clamp(18px, 1vw, 22px);
  --font-size-23: clamp(18px, 1vw, 23px);
  --font-size-24: clamp(16px, calc(2vw + 1rem), 24px);
  --font-size-25: clamp(16px, calc(2vw + 1rem), 25px);
  --font-size-26: clamp(17px, calc(2vw + 1rem), 26px);
  --font-size-27: clamp(18px, calc(2vw + 1rem), 27px);
  --font-size-28: clamp(18px, calc(2vw + 1rem), 28px);
  --font-size-29: clamp(19px, calc(2vw + 1rem), 29px);
  --font-size-30: clamp(20px, calc(2vw + 1rem), 30px);
  --font-size-31: clamp(20px, calc(2vw + 1rem), 31px);
  --font-size-32: clamp(21px, calc(2vw + 1rem), 32px);
  --font-size-33: clamp(22px, calc(2vw + 1rem), 33px);
  --font-size-34: clamp(22px, calc(2vw + 1rem), 34px);
  --font-size-35: clamp(23px, calc(2vw + 1rem), 35px);
  --font-size-36: clamp(24px, calc(2vw + 1rem), 36px);
  --font-size-37: clamp(24px, calc(2vw + 1rem), 37px);
  --font-size-38: clamp(25px, calc(2vw + 1rem), 38px);
  --font-size-39: clamp(26px, calc(2vw + 1rem), 39px);
  --font-size-40: clamp(26px, calc(2vw + 1rem), 40px);
  --font-size-41: clamp(27px, calc(2vw + 1rem), 41px);
  --font-size-42: clamp(28px, calc(2vw + 1rem), 42px);
  --font-size-43: clamp(28px, calc(2vw + 1rem), 43px);
  --font-size-44: clamp(29px, calc(2vw + 1rem), 44px);
  --font-size-45: clamp(30px, calc(2vw + 1rem), 45px);
  --font-size-46: clamp(30px, calc(2vw + 1rem), 46px);
  --font-size-47: clamp(31px, calc(2vw + 1rem), 47px);
  --font-size-48: clamp(32px, calc(2vw + 1rem), 48px);
  --font-size-49: clamp(32px, calc(2vw + 1rem), 49px);
  --font-size-50: clamp(33px, calc(2vw + 1rem), 50px);


  /*================================== font definitons =========================================*/

  --font-weight-bold: 700;
  --font-weight-600: 600;
  --font-weight-medium: 500;
  --font-weight-regular: 400;
  --fc-today-bg-color: #E9EAFE !important;
  /*================================== font definitons end=========================================*/
}

.rdp-root {
  --rdp-accent-color: #7413BD !important;
  --rdp-accent-background-color: #F6EAFF !important;
}


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Mulish" !important;
  white-space: pre-wrap;
  overscroll-behavior: none;
}

.global-required::before {
  color: #FF4D4F;
  margin-right: 5px;
  font-weight: 600;
  content: "*";
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: "Mulish";
}

body {
  font-family: "Mulish";
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(to bottom,
      transparent,
      rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
}

b {
  font-weight: 600;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  white-space: pre;
}

/* ========================================= globally defined classnames ========================================= */

.global-page-header {
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-bold);
  display: flex;
  align-items: center;
  /* text-transform: capitalize; */
}

.global-page-header-2 {
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-bold);
}

.global-title-subheader {
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-medium);
  /* text-transform: capitalize; */
}

.global-subheader {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-medium);
  /* text-transform: capitalize; */
}

.global-button-text {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-bold);
  /* text-transform: capitalize; */
}

.form-100-percent-width {
  width: 100%;
}

.select-border-style .ant-select-selector {
  border: 1px solid transparent !important;
  border-radius: 7px;
}

.select-border-style .ant-select-arrow {
  color: #5B5B5B !important;
}

.global-button-text-bold {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-bold);
}

.global-button-text-regular {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-regular);
}

.global-table-header-text {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-bold);
}

.global-table-other-text {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-medium);
}

.global-label-text {
  font-size: var(--font-size-13);
  font-weight: var(--font-weight-regular);
}

.global-body-text {
  font-size: var(--font-size-15);
  font-weight: var(--font-weight-regular);
  white-space: pre-wrap;
}

.global-card-header {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-bold);
}

.global-subheader-regular {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-regular);
  text-transform: capitalize;
}

.form-subheader {
  font-size: var(--font-size-18);
  font-weight: 700;
  text-transform: capitalize;
}

.global-form-header {
  text-decoration: underline;
  font-size: var(--font-size-16);
  font-weight: 600;
  text-transform: capitalize;
}

.global-regular-font {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-regular);
}

.global-body-medium {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-medium);
}

.ant-picker .ant-picker-input>input:placeholder-shown {
  font-size: var(--font-size-16);
}

.ant-select-lg.ant-select-show-arrow .ant-select-selection-placeholder {
  font-size: var(--font-size-16);

}

.ant-select-lg.ant-select-show-arrow .ant-select-selection-item {
  font-size: var(--font-size-16);
}

.ant-picker-large .ant-picker-input>input {
  font-size: var(--font-size-16);
}

.ant-input-lg {
  font-size: 16px !important;
}

.ant-picker-input>input {
  font-size: 16px !important;
}

.req-label {
  color: #5B5B5B;
  margin-bottom: 12px;
}

.global-display-flex-space-between-important {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.ant-drawer {
  z-index: 2000 !important;
}

.inline-flex-center {
  display: inline-flex;
  align-items: center;
}

.color-red {
  color: red;
}

.margin-right-3 {
  margin-right: 3px;
}

.display-flex-center {
  display: flex;
  align-items: center;
  gap: 8px;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.text-wrap {
  text-wrap: wrap;
}

.font-weight-600 {
  font-weight: 600;
}

/* ========================================= globally defined classnames end ========================================= */


.week-days-container {
  display: flex;
  gap: 10px;
}

.week-day-label {
  padding: 4px 8px;
  font-size: var(--font-size-12);
  border-radius: 8px;
}

.info-icon-style {
  height: 18px;
  width: 18px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.m-lft-5 {
  margin-left: 5px;
}

.custom-modal-padding .ant-modal-body {
  margin: 15px 0px;
}

.form-item-custom-margin-10-4.ant-form-item {
  margin-top: 10px !important;
  margin-bottom: 4px !important;
}

.form-item-custom-margin-5.ant-form-item {
  margin-top: 5px !important;
}

.hide-form-item-label.ant-form-item .ant-form-item-label>label {
  display: none !important;
}

.custom-form-item-label-margin.ant-form-item .ant-form-item-label {
  margin: 25px 0px 15px 0px;
}

.input-num-w-100percent.ant-input-number {
  width: 100% !important;
}

.input-num-w-100p-with-prefix-icon .ant-input-number {
  width: 100% !important;
}

.input-num-w-100p-with-prefix-icon.ant-input-number-affix-wrapper {
  width: 100% !important;
}

input-num-w-100percent input::placeholder,
.ant-select-selection-placeholder {
  font-size: var(--font-size-16) !important;
}

.ant-input {
  font-weight: 300 !important;
  font-size: var(--font-size-16) !important;
}

.ant-select-selection-item,
.ant-picker-input input,
.ant-select-item-option {
  font-weight: 300 !important;
  font-size: var(--font-size-16) !important;
}

.languageSelect .ant-select-selection-item {
  font-weight: 400 !important;
  font-size: var(--font-size-14) !important;
}

.input-number-width-100percent.ant-input-number {
  width: 100% !important;
}

:where(.ant-modal) {
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.global-display-flex-between-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px
}

.global-required::before {
  color: #ff4d4f;
  margin-right: 5px;
  font-weight: 600;
  content: "*";
}

.ml-5 {
  margin-left: 5px;
}

@media (prefers-color-scheme: light) {
  html {
    color-scheme: light;
  }
}

@media only screen and (max-width:620px) {
  .ant-picker-panels {
    flex-direction: column !important;
    max-height: 370px !important;
    overflow-y: auto !important;
  }
}

.global-flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-16 {
  margin-bottom: 16px;
}

.global-flex {
  display: flex;
  align-items: center;
}

.global-flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


/* tablewrapper helper classes */

.tw-cell-overflow {
  max-width: 400px !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}


/* mobile calendar custom date picker */

.calendar-custom-date-picker.ant-picker-outlined {
  border: none !important;
}

.calendar-custom-date-picker .ant-picker-input input {
  width: 130px !important;
  font-weight: 600 !important;
}

.calendar-custom-date-picker.ant-picker {
  padding: 3px 5px !important;
}

.calendar-custom-down-arrow {
  height: var(--font-size-24);
  width: var(--font-size-24);
}

.month-date-picking {
  margin: 15px 0px !important;
  display: flex !important;
  justify-content: space-between !important;
}


/* Custom mobile calendar styles */

.custom-mobile-calendar .rdp-selected {
  background-color: white !important;
}

.custom-mobile-calendar .rdp-weekday {
  color: #7413BD !important;
}

.custom-mobile-calendar .rdp-week {
  border-top: 0.3px solid #AEAEB2 !important;
}

.custom-mobile-calendar .rdp-day {
  height: 65px !important;
  width: 55px !important;
}

.custom-mobile-calendar .rdp-month_caption {
  display: none !important;
}


.custom-mobile-calendar .rdp-nav {
  position: absolute !important;
  top: -50px !important;
}

.custom-mobile-calendar .rdp-selected .rdp-day_button .mobile-calendar-date {
  border: 1px solid #7413BD !important;
  background-color: #7413BD !important;
  border-radius: 50% !important;
  color: white !important;
  height: 50px;
  width: 35px;
}

.custom-mobile-calendar .rdp-selected .rdp-day_button {
  border: none !important;
}

.mobile-calendar-date {
  padding: 5px !important;
  margin-top: 5px !important;
}

.rdp-day_button.calender-date-container {
  display: inline-flex !important;
  flex-direction: column !important;
  padding: 5px !important;
}

.calender-date-inline-metadata {
  display: inline-flex;
  padding: 5px;
  gap: 5px;
}

.event-circle {
  height: 5px;
  width: 5px;
  border-radius: 50%;
}


/* laptop-mobile view toggle */

@media only screen and (max-width: 940px) {

  .mobile-view {
    display: block !important;
  }

  .laptop-view {
    display: none !important;
  }

  .hide-ant-tabs-nav-in-mob-view .ant-tabs-nav {
    display: none !important;
  }
}


.mobile-view {
  display: none;
}

.laptop-view {
  display: block;
}

.hide-ant-tabs-nav-in-mob-view .ant-tabs-nav {
  display: block;
}

.container-below-mobile-view-toggle {
  overflow-y: auto; 
  position: relative; 

  @media only screen and (max-width: 576px) {
    height: calc(100vh - 145px) !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 940px) {
    height: calc(100vh - 165px) !important;
  }
}


/* mobile toggle css for (ToggleComponent) */

.mob-toggle-container-div {}

.mob-toggle-outer-div {
  margin: 10px 0px 20px 0px;
  display: flex;
  background-color: #F8F8F8;
  padding: 4px;
  border-radius: 10px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.mob-toggle-inner-div-selected {
  background-color: #7413BD;
  color: white;
  border-radius: 8px;
  border: 0.5px solid #7413BD;
  padding: 5px 10px;
  width: 50%;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-15);
  font-weight: 700;
  cursor: pointer;
}

.mob-toggle-inner-div-unselected {
  color: #5B5B5B;
  padding: 5px 10px;
  width: 50%;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-15);
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.5s ease;
}


/* Responsive custom-popover */ 

#mobile-responsive-popover.ant-popover-inner {

  @media only screen and (max-width: 576px) {
    padding: 0px !important; 
  }
}
